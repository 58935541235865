const moment = require('moment');

const yearMatchToDates = (matches) => {
    let year = matches[2];
    return {startDate: new Date(parseInt(year), 0, 1), endDate: new Date(parseInt(year), 11, 31)};
};

const halfMatchToDates = (typeMatches) => {
    let half = parseInt(typeMatches[1]);
    let year = parseInt(typeMatches[2]);
    return {startDate: new Date(parseInt(year), half === 1 ? 0 : 6, 1),
        endDate: new Date(parseInt(year), half === 1 ? 5 : 11, half === 1 ? 30 : 31)};
};

const monthMatchToDates = (typeMatches) => {
    let start = moment("1 " + typeMatches[0], "DD MMM YYYY");
    let end = start.clone().endOf('month');

    return {startDate: start.toDate(), endDate: end.toDate()};
};

const quarterMatchToDates = (typeMatches) => {
    let quarter = parseInt(typeMatches[1]);
    let year = parseInt(typeMatches[2]);
    const quarterData = {
        1 : {startMonth: 0, endMonth: 2, endDay: 31},
        2 : {startMonth: 3, endMonth: 5, endDay: 30},
        3 : {startMonth: 6, endMonth: 8, endDay: 30},
        4 : {startMonth: 9, endMonth: 11, endDay: 31}
    }; 
    return {startDate: new Date(parseInt(year), quarterData[quarter].startMonth, 1),
        endDate: new Date(parseInt(year), quarterData[quarter].endMonth, quarterData[quarter].endDay)};
};

export const goalsForDate = (goals, date) => {
    var resultMarkdown = "";
    goals.map((goal) => {
        var include = false;
        if (date >= goal.startDate && date <= goal.endDate) {
            let dow = date.getDay();
            let dom = date.getDate();
            switch(goal.type) {
                case("year"):
                  include = (dom === 1);
                  break;
                case("half"):
                  include = (dom === 1);
                  break;
                case("quarter"):
                  include = (dow === 0);
                  break;
                case("month"):
                  include = (dow === 0);
                  break;
            }
        }
        if (include) {
            resultMarkdown += goal.text + "\n\r";
        }
    });
    if (resultMarkdown) {
        return resultMarkdown;
    }
    return null;
};

export const parseGoals = (doc) => {
    const headingRegex = new RegExp(`^(#{1,3})\\s(.*)$`, "gmi");
    const typeMatchers = {
        'year': new RegExp(`^(2\\d{3})$`),
        'half': new RegExp(`^H([12]) (2\\d{3})`, "i"),
        'quarter': new RegExp(`^Q([1234]) (2\\d{3})`, "i"),
        'month': new RegExp(`(\\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|(Nov|Dec)(?:ember)?)\\D?)((\\s*[,.\\-\\/]\\s*)\\D?)?\\s*((19[0-9]\\d|20\\d{2}))+`,"i"),
        // TODO - weeks?
        //'week': new RegExp(`week of ((\\b\\d{1,2}\\D{0,3})?\\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|(Nov|Dec)(?:ember)?)\\D?)(\\d{1,2}(st|nd|rd|th)?)?((\\s*[,.\\-\\/]\\s*)\\D?)?\\s*((19[0-9]\\d|20\\d{2})|\\d{2})*`,"i"),        
    };

    
    var matched = [];
    var matches = null;
    while ((matches = headingRegex.exec(doc)) !== null) {
        
        // mark the end of the previous match.
        if (matched.length > 0 && !matched[matched.length - 1].end) {
            matched[matched.length - 1].end = matches.index - 1;
        }

        var result = {}
        const matchedHeader = matches[2];
        for (const t in typeMatchers) {
            let typeMatch = typeMatchers[t].exec(matchedHeader);
            if (typeMatch) {
                result.type = t;
                result.name = matchedHeader;
                result.start = matches.index;
                result.matches = matches;
                result.typeMatch = typeMatch;
                // TODO: add startdate and enddate
                break;
            }
        }

        if (result.type) {
            matched.push(result);
        }
    }
    for (const m in matched) {
        matched[m].text = doc.substring(matched[m].start, matched[m].end);
        switch (matched[m].type) {
            case("year"):
              matched[m] = {...matched[m], ...yearMatchToDates(matched[m].matches)};
              break;
            case("half"):
              matched[m] = {...matched[m], ...halfMatchToDates(matched[m].typeMatch)};
              break;
            case("quarter"):
              matched[m] = {...matched[m], ...quarterMatchToDates(matched[m].typeMatch)};
              break;
            case("month"):
              matched[m] = {...matched[m], ...monthMatchToDates(matched[m].typeMatch)};
              break;   
        }

        matched[m].matches = null;
        matched[m].typeMatch = null;
    }
    return matched;
}

export const goalsDefaultContent = () => {
    return `:::tip
This is your goals page. It has special powers. When you create lists of goals under year, half, quarter or month headings, they will appear automatically as prompts for your journal entries to help you stay on track. (BTW, you can delete this tip).
:::
---
### 2021
- [ ] Example: Exercise at least 3 times a week
- [ ] Example: Maintain a 365 day streak on Duolingo
- [ ] Example: Write in my journal every day

### H1 2021
- [ ] Example: Learn to play guitar

### Q1 2021
- [ ] Example: Write 3 blog posts

### January 2021
- [ ] Example: Complete one piece of art every day
`;
}