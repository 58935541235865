import React from "react";
import DashboardSection from "./../components/DashboardSection";
import { requireAuth } from "./../util/auth.js";
import { useRouter } from "./../util/router.js";

function JournalPage(props) {
  const router = useRouter();

  return (
    <DashboardSection
      color="white"
      size="medium"
      title={router.query.day}
      year={router.query.year}
      month={router.query.month}
      day={router.query.day}
      ></DashboardSection>
  );
}

export default requireAuth(JournalPage);
