import React from "react";
import SectionButton from "./SectionButton";

import Download from '@axetroy/react-download';

function KeyDownload(props) {
  const privateKey = localStorage.getItem("privateKey");
  const publicKey = localStorage.getItem("publicKey");

  return (
    <div>
      <Download file="tekkoh-key.txt" content={"TECHOKEY/" + privateKey + "////" + publicKey + "////"}>
      <SectionButton
            size="medium"
          >Download Keys
      </SectionButton>
      </Download>
    </div>
  );
}

export default KeyDownload;
