import React, { useState, createRef } from "react";
import FormField from "./FormField";
import SectionButton from "./SectionButton";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import "./SettingsGeneral.scss";
import { injectKeys } from "./../util/crypto.js";
import KeyDownload from "./KeyDownload";

function SettingsGeneral(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const privateKey = localStorage.getItem("privateKey");
  const publicKey = localStorage.getItem("publicKey");
  const keysField = createRef();

  const copyKeys = () => {
    keysField.current.select();
    keysField.current.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };


  const onSubmit = (data) => {
    console.log(data);
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your profile has been updated",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormField
        name="name"
        type="text"
        label="Name"
        defaultValue={auth.user.name}
        placeholder="Name"
        error={errors.name}
        inputRef={register({
          required: "Please enter your name",
        })}
      ></FormField>
      <FormField
        name="email"
        type="email"
        label="Email"
        defaultValue={auth.user.email}
        placeholder="Email"
        error={errors.email}
        inputRef={register({
          required: "Please enter your email",
        })}
      ></FormField>
      <FormField
        name="streaknotifications"
        type="select"
        label="Streak Notifications"
        defaultValue={auth.user.streaknotifications}
        items={[{value: 'email', label: 'by e-mail'},{value: 'off', label: 'off'}]}
        placeholder="Streak"
        error={errors.streak}
        inputRef={register({
          required: "Please enter value",
        })}
      ></FormField>
      <div className="field">
        <div className="control">
          <SectionButton
            parentColor={props.parentColor}
            size="medium"
            state={pending ? "loading" : "normal"}
          >
            Save
          </SectionButton>
        </div>
      </div>
      <div className="field">
      <i className="far fa-copy" onClick={copyKeys}></i> Keys<br/>
      <KeyDownload />
      <br/>
      <textarea className="SettingsGeneral__keyField" ref={keysField} defaultValue={"TECHOKEY/" + privateKey + "////" + publicKey + "////"} />
      </div>
    </form>
    <SectionButton
      size="medium"
      onClick={() => {
        injectKeys(auth.user.uid, keysField.current.value).then(
          (status) => {
            if (status.error) {
              window.alert("Error " + status.error);
            } else {
              window.alert(status.success);
            }
          });
        }}
    >
      Use Pasted Keys
    </SectionButton>
  </>
  );
}

export default SettingsGeneral;
