import Editor from "rich-markdown-editor";
import { debounce } from "lodash";

import React, { useEffect, useState, createRef } from "react";
import Section from "./Section";
import { Link, useRouter } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import "./DashboardSection.scss";
import { light } from "./EditorTheme";
import DayTopSection from "./DayTopSection";

import './Calendar.scss';

import { apiRequest } from "./../util/util.js";
import { updateEntry, readEntry, readEntryMap, readKeyChecker } from "./../util/db.js";
import { generateMessageKey, importKeysFromLocalStorage, generateKeyPair, encryptWithMessageKey, decryptWithMessageKey, decrypt, writeKeyCheck, encryptFileWithMessageKey, decryptFileWithMessageKey } from "./../util/crypto.js";
import firebase from "./../util/firebase.js";

import TimeAgo from 'timeago-react';
import KeyDownload from "./KeyDownload";
import { useGlobals } from "../util/global";
import useMousetrap from "react-hook-mousetrap"
import { useBeforeunload } from 'react-beforeunload';

import GoalsTopSection from "./GoalsTopSection";
import { goalsDefaultContent, goalsForDate, parseGoals } from "../util/goals.js";

const sprintf = require('sprintf-js').sprintf;
const Holidays = require('date-holidays');
const hd = new Holidays('US', 'CA');
const moment = require('moment');

const dateForPage = (props) => {
 if (props.type === "goals") {
   return new Date(sprintf("%04d-%02d-%02dT12:00", 1900, 1, 1));
 } else {
   return !props.year ?
     new Date() : new Date(sprintf("%04d-%02d-%02dT12:00", props.year, props.month, props.day));
 }
};

function DashboardSection(props) {
  const type = props.type || "day";
  const auth = useAuth();
  const router = useRouter();
  const [localActualDay, setLocalActualDay] = useState(new Date());
  const [date, setDate] = useState(dateForPage(props));
  const [loc, setLoc] = useState(null);
  const [locMetadata, setLocMetadata] = useState({});
  const [locFieldIsEditing, setlocFieldIsEditing] = useState(false);
  const [newKeysWereGenerated, setNewKeysWereGenerated] = useState(false);
  const [mismatchedKeysFound, setMismatchedKeysFound] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [editsPending, setEditsPending] = useState(null);
  const [publicKey, setPublicKey] = useState(null);
  const [messageKey, setMessageKey] = useState(null);
  const [hasBeenRead, setHasBeenRead] = useState(false);
  const [goalsHaveBeenRead, setGoalsHaveBeenRead] = useState(false);

  const [placeholder, setPlaceholder] = useState(null);
  const [entryMap, setEntryMap] = useState(null);
  const [holiday, setHoliday] = useState(null);
  const [markdown, setMarkdown] = useState(null);
  const [goals, setGoals] = useState(null);
  const globals = useGlobals();

  const m = moment(date);
  const dateToken = (d) => d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  const isToday = dateToken(date) === dateToken(localActualDay);
  const editor = createRef();
  const locField = createRef();

  const hasEntry = (d) => {
    return entryMap && entryMap.includes(dateToken(d));
  };

  const encryptAndUploadImage = (file) => {
    return new Promise(resolve => {
      encryptFileWithMessageKey(file, messageKey, publicKey, (cryptJson) => {
        const path = "/users/" + auth.user.uid + "/images/" + dateToken(date) + "/" + file.name;
        let ref = firebase.storage().ref(path);
        ref.put(new Uint8Array(cryptJson.ciphertext)).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
          });
          resolve("https://i.tekkoh.com" + path + "?uk=" + cryptJson.urlKey);
        });
      });
    });
  }

  const downloadAndDecryptImage = (url, targetElement) => {
    let [path, b64key] = (url.split("https://i.tekkoh.com")[1]).split("?uk=");
    let ref = firebase.storage().ref();
    let fileRef = ref.child(path);
    fileRef.getDownloadURL().then((url) => {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function(event) {
          var blob = xhr.response;
          const urlKeyJson = JSON.parse(atob(b64key));
          decryptFileWithMessageKey(blob, urlKeyJson, (dataUri) => {
            targetElement.src = dataUri;
          });
        };
        xhr.open('GET', url);
        xhr.send();
    });
  }

  const incrementStreak = () => {
    var streak = (auth.user.streak && parseInt(auth.user.streak)) || 0;
    streak = streak + 1;
    auth.updateProfile({streak: streak.toString()});
    var entries = entryMap || [];
    entries.push(dateToken(date));
    setEntryMap(entries);
  }

  const reallyPersistChange = (value) => {
    const willIncrementStreak = isToday && !hasEntry(date);
    encryptWithMessageKey(value(), messageKey, publicKey).then((encrypted) => {
      updateEntry(
        auth.user.uid, 
        dateToken(date),
        {loc: loc, locMetadata: locMetadata}, encrypted.cipherkey, encrypted.ciphertext);
      setLastUpdated(new Date());
      setEditsPending(false);
      if (willIncrementStreak) {
        incrementStreak();
      }  
    });
  }

  const maybePersistChange = debounce((value) => {
    reallyPersistChange(value);
  }, 2000);

  const handleChange = (value) => {
    const willIncrementStreak = isToday && !hasEntry(date);
    if (willIncrementStreak) {
      globals.setOnFire("yes");
    }
    setEditsPending(true);
    setMarkdown(value());
    maybePersistChange(value);
  };

  const parseKeys = async (onComplete) => {
    if (!messageKey) {
      setMessageKey("generating...");
      setMessageKey(await generateMessageKey());
    }

    if (localStorage.getItem("encryptionKeys") !== "generated") {
      return;
    }
    
    var keys = await importKeysFromLocalStorage()

    setPublicKey(keys.publicKey);
    if (onComplete) {
      onComplete(keys.privateKey);
    }
    return keys.publicKey;
  };

  const generateKeys = (onComplete) => {
    generateKeyPair().then(() => {
      auth.updateProfile({streaknotifications: "email"});
      setNewKeysWereGenerated(true);
      parseKeys(onComplete).then((pubKey) => {
        writeKeyCheck(auth.user.uid, pubKey, false);
      });
    });
  };
  
  const initKeys = (onComplete) => {
    const keys = localStorage.getItem("encryptionKeys");
    if (!keys) {
      generateKeys(onComplete);
    } else {
      parseKeys(onComplete);
    }
  }

  const maybeReadGoals = (privateKey, placeholder) => {
    if (type === "goals") {
      if (placeholder === null) {
        setPlaceholder(goalsDefaultContent());
      }
      setGoalsHaveBeenRead(true);
    } else {
      readEntry(auth.user.uid, dateForPage({type:"goals"}), (data) => {
        if (data) {
          decryptWithMessageKey(
            data.cText.toUint8Array(), 
            data.cKey.toUint8Array(),
            privateKey).then((value) => {
              setGoals(parseGoals(value));
              if (!placeholder && goals) {
                setPlaceholder(goalsForDate(goals, date));
              }
              setGoalsHaveBeenRead(true);
          }); 
        } else {
          setGoalsHaveBeenRead(true);
        }
      });
    }
  }

  const loadInitialContent = (privateKey) => {

    console.log("loadInitialContent");

    if (privateKey && !hasBeenRead) {
      readEntryMap(auth.user.uid, (data) => {
        if (data && data.entries) {
          setEntryMap(data.entries);
        }
      });

      readKeyChecker(auth.user.uid, (data) => {
        if (data) {
            decrypt(data.checker.toUint8Array(), privateKey)
              .then((decrypted) => {
                if (decrypted !== "TECHO") {
                  setMismatchedKeysFound(true);
                }
              })
              .catch(err => setMismatchedKeysFound(true));
        }
      });

      readEntry(auth.user.uid, date, (data) => {

        if (data) {
          decryptWithMessageKey(
            data.cText.toUint8Array(), 
            data.cKey.toUint8Array(),
            privateKey).then((value) => {
              setMarkdown(value);
              setPlaceholder(value);
              setHasBeenRead(true);
              maybeReadGoals(privateKey, value);
          });
          if (data.loc) {
            setLoc(data.loc);
            setLocMetadata(data.locMetadata);
          }
        } else {
          setPlaceholder(null);
          setHasBeenRead(true);
          maybeReadGoals(privateKey, null);
        }


      });



      const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (auth.user && !auth.user.browserTz) {
        auth.updateProfile({browserTz: browserTz});
      } else if (auth.user && auth.user.browserTz !== browserTz) {
        auth.updateProfile({browserTz: browserTz});
      }

    }
  }

  const geoLocate = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLoc(position.coords.latitude + "," + position.coords.longitude);
        apiRequest("city-lookup", "POST", {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate()
        })
          .then((result) => {
            setLocMetadata(result);
            if (result.city) {
              setLoc(result.city);
            }
          });
      }, (err) => {console.log(err);}, 
      {maximumAge:60000, timeout:10000, enableHighAccuracy:true});
    } else {
      console.log("Geolocation Not Available");
    }
  }

  const installWindowErrorHookForImageDecryption = () => {
    window.addEventListener("error", (event) => {
      if (event.target && event.target.src && event.target.src.startsWith("https://i.tekkoh.com")) {
        // in business! Note that i.tekkoh.com doesn't serve anything on the actual internet,
        // we just know that we *own* this domain for this purpose.
        downloadAndDecryptImage(event.target.src, event.target);
      }
    }, true);
  };

  useBeforeunload((event) => {
    if (editsPending) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    installWindowErrorHookForImageDecryption();
  }, []);

  useMousetrap("alt+left", () => {prevDay();});
  useMousetrap("alt+right", () => {nextDay();});
  useMousetrap("command+esc", () => {
    editor.current.focusAtEnd();
  });

  useEffect(() => {
    console.log("@" + router.pathname + "  " + props.year + "-" + props.month + "-" + props.day);
    // if (!props.year) {
    //    geoLocate();
    // }
    // } else {
    //   setLoc("Add Location...");
    // }
    setLoc("Add Location...");
    initKeys(privateKey => loadInitialContent(privateKey));
    const hol = hd.isHoliday(date);
    if (hol) {
      setHoliday(hol.name);
    }
  }, [props.day]);

  useEffect(() => {
    if (locField.current) {
      locField.current.focus();
    }
  }, [locFieldIsEditing]);
  
  const goToDate = (d) => {
    setHoliday(null);
    setLastUpdated(null);
    setHasBeenRead(false);
    setGoalsHaveBeenRead(false);
    setPlaceholder(null);
    setDate(d);
    router.push('/journal/' + d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate());
  }

  const nextDay = () => {
    const d = date;
    d.setDate(d.getDate() + 1);
    goToDate(d);
  };

  const prevDay = () => {
    const d = date;
    d.setDate(d.getDate() - 1);
    goToDate(d);
  }


  return (
    <Section color={props.color} size={props.size} className="DashboardSection__section">
      <div className="container DashboardSection__container">
        {router.query.paid && auth.user.planIsActive && (
          <article className="DashboardSection__paid-message message is-success mx-auto">
            <div className="message-body">
              You are now a pro subscriber&nbsp;&nbsp;<span role="img" aria-label="party emoji">🥳</span>
            </div>
          </article>
        )}
        {(newKeysWereGenerated) && (
          <article className="DashboardSection__paid-message message is-success mx-auto">
            <div className="message-body">
            <span role="img" aria-label="lock emoji">👋</span> Welcome to tekkoh!
              <br/>Write an entry every day to keep your streak alive. We'll send email reminders. You can turn them off in <Link to="/settings/general">Settings</Link>.
            </div>
          </article>
        )}
        {(newKeysWereGenerated && !mismatchedKeysFound) && (
          <article className="DashboardSection__paid-message message is-success mx-auto">
            <div className="message-body">
            <span role="img" aria-label="lock emoji">🔐</span> New encryption keys generated!
              <br/>Your keys are stored <b>only</b> in your browser. If you lose them, your journal cannot be decrypted.<br/>
              You should download your keys now and keep them in a safe place.<br/><br/>
              <KeyDownload />
            </div>
          </article>
        )}
        {mismatchedKeysFound && (
          <article className="DashboardSection__paid-message message is-danger mx-auto">
            <div className="message-body">
            <span role="img" aria-label="lock emoji">🔐</span> Mismatched keys detected!<br/>
              Paste the contents of the key file you downloaded (possibly on another device) in <Link to="/settings/general">Settings</Link>.
              <br/>Your keys are stored <b>only</b> in your browser. If you lose them, your journal cannot be decrypted.<br/>
            </div>
          </article>
        )}
        {type === "day" && (
          <DayTopSection 
            m = {m}
            locMetadata = {locMetadata}
            holiday = {holiday}
            goToDate = {goToDate}
            entryMap = {entryMap}
            date = {date}
          />
        )}
        {type === "goals" &&
        <GoalsTopSection
          markdown={markdown}
          editor={editor}
         />
        }
        
        <div className="columns is-vcentered is-desktop Editor-main">
          <div className="column is-12-desktop">
          {!locFieldIsEditing && type === "day" &&
          <>
        <p className="DashboardSection__locField is-hidden-touch" onClick={() => {setlocFieldIsEditing(!locFieldIsEditing);}}><span className="icon">
          {(loc && <i className="fas fa-map-marker-alt"></i>) || <span className="loader is-loading"/>}</span>{loc}</p>
          <div className="DashboardSection__mobButtons is-hidden-desktop">
            <span className="DashboardSection__prevDay is-flex-touch">
            <button className="button" onClick={prevDay}>&larr;</button>
          </span>
          <span className="DashboardSection__nextDay is-flex-touch">
          <button className="button" onClick={nextDay}>&rarr;</button>
          </span>
          </div>
          </>
          }
          {locFieldIsEditing &&
          <div className="control field column is-4">
            <i className="fas fa-location-arrow button"
              onMouseDown={(e) => {e.preventDefault();} }
              onClick={(e) => {
                console.log("gl");
                setLoc(null);
                geoLocate();
                setlocFieldIsEditing(false);
                }}></i>
              <input
                ref={locField}
                tabIndex={0}
                className={`input is-medium is-4`}
                name="location"
                type="text"
                placeholder={loc}
                onBlur={(e) => {
                  console.log(e);
                  if (e.target.value) {
                    setLoc(e.target.value);
                    handleChange(editor.current.value);
                  }
                  setlocFieldIsEditing(false);
                  }}
              ></input>
            </div>
          }
          { hasBeenRead && goalsHaveBeenRead &&
          <>
          <div className="DashboardSection__mobileSpacer is-block-mobile is-hidden-desktop">
          </div>

          <hr/>
          <Editor
            className="DashboardSection__editor"
            defaultValue={placeholder || ""}
            placeholder=" Stuff I did, people I met, things to remember..."
            onSave={() => reallyPersistChange(editor.current.value)}
            onShowToast={message => window.alert(message)}
            onChange={handleChange}
            theme={light}
            key={router.pathname}
            ref={editor}
            uploadImage={encryptAndUploadImage}
            onClickHashtag={tag => {
              console.log("Click hashtag " + tag);
            }}
            onCancel={() => {
              document.activeElement.blur();
            }}
//            extensions={[new SelectionSizeExtension()]}
          />
          <p>&nbsp;</p>
          <p>{lastUpdated && <>🔐 Encrypted &amp; Saved <TimeAgo datetime={lastUpdated} /></>}</p>
          </>
          }
          </div>
        </div>

      </div>
              
    </Section>
  );
}

export default DashboardSection;