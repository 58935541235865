import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Features from "./Features";

function FeaturesSection(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        ></SectionHeader>

        <Features
          items={[
            {
              title: "🔑 Master Key",
              description:
                "A 4096 bit RSA-OAEP master key pair is generated on your own computer the first time you use Tekkoh. They are never sent to the cloud.",
              image: "/master_key.png",
            },
            {
              title: "🔐 Per-Entry Keys",
              description:
                "For maximum security, every entry in your journal is encrypted with a different key. A per-entry key is generated by your computer every time you start (or edit) a new entry.",
              image:
                "/per_message_keys.png",
            },
            {
              title: "⛅️ Cloud Convenience",
              description:
                "Each entry is encrypted with its key and the key itself is encrypted with your master key. The encrypted entry is stored in the cloud so you have the convenience of viewing and editing entries across devices, as well as useful features like streak tracking encouraging you to write every day.",
              image: "/cloud.png",
            },
            {
              title: "👩‍💻 Secure",
              description:
                "All data stored in the cloud has been encrypted on your local device. It's impossible for Tekkoh to decrypt it except back on your own device(s). That means nobody can read your entries but you.",
              image: "/secure.png"
            },
          ]}
        ></Features>
      </div>
    </Section>
  );
}

export default FeaturesSection;
