import React from "react";
import "./DashboardSection.scss";
import Calendar from "react-calendar";
import { datesAreSameDay } from "./../util/util.js";

const moment = require('moment');

const dateToken = (d) => d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();

const hasEntry = (entryMap, d) => {
    return entryMap && entryMap.includes(dateToken(d));
};

function DayTopSection(props) {

  return (
    <div className="columns is-desktop mt-5 DashboardSection__main">
      <div className="column is-3-desktop">
            <div className="content">
              <span className="DashboardSection__bigday">{props.m.format("D")}</span>
              <br/>
            { props.locMetadata && props.locMetadata.sun &&
            <span className="DashboardSection__sunspan"><i className="far fa-sun"></i>&nbsp;
            {moment(new Date(props.locMetadata.sun['sunrise'])).format("HH:mm")}
            &nbsp;-&nbsp;<i className="far fa-moon"></i>&nbsp;
            {moment(new Date(props.locMetadata.sun['sunset'])).format("HH:mm")}
            </span>}
            </div>
          </div>
          <div className="column is-3-desktop">
            <div className="content">
            <span className="DashboardSection__dayofweek">{props.m.format("dddd")}</span><br/>
            <span className="DashboardSection__monthandyear">{props.m.format("MMMM YYYY")}</span>
            {props.holiday &&
              <><br/><span className="DashboardSection__holiday">{props.holiday}</span></>
            }

            </div>
          </div>
          <div className="column is-1"></div>
          <div className="column DashboardSection__calendar is-hidden-touch">
          <Calendar
           onChange={(d) => {
            if (!datesAreSameDay(props.date, d)) {
              props.goToDate(d);
            }
          }}
          tileClassName={({ date: d, view }) => view === 'month' && hasEntry(props.entryMap, d) ? 'hasEntry' : null}
          ></Calendar>
          </div>
        </div>
  );
}

export default DayTopSection;
