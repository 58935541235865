import { writeKeyChecker } from "./db";
import { base64ArrayBuffer } from "./base64ArrayBuffer";

export async function encrypt(message, publicKey) {
    let encoded = new TextEncoder().encode(message);
    let ciphertext = await window.crypto.subtle.encrypt({
        name: "RSA-OAEP"
    },
    publicKey,
    encoded
    );
  return ciphertext;
}

export async function encryptWithMessageKey(message, messageKey, publicKey) {
  const messageKeyJSON = JSON.stringify(await crypto.subtle.exportKey(
    "jwk",
    messageKey));
  let encoded = new TextEncoder().encode(messageKeyJSON);
  let cipherkey = await window.crypto.subtle.encrypt({
    name: "RSA-OAEP"
    },
    publicKey,
    encoded
  );
  encoded = new TextEncoder().encode(message);
  let ciphertext = await window.crypto.subtle.encrypt({
    name: "AES-CTR", counter: new Uint8Array(16), length: 16*8
    },
    messageKey,
    encoded
  );
  
  return {cipherkey: cipherkey, ciphertext: ciphertext};
}

export async function encryptFileWithMessageKey(file, messageKey, publicKey, onComplete) {
  const messageKeyJSON = JSON.stringify(await crypto.subtle.exportKey(
    "jwk",
    messageKey));
  let encoded = new TextEncoder().encode(messageKeyJSON);
  let cipherkey = await window.crypto.subtle.encrypt({
    name: "RSA-OAEP"
    },
    publicKey,
    encoded
  );
  console.log(messageKeyJSON);

  const reader = new FileReader();
  reader.onload = event => {
    window.crypto.subtle.encrypt({
      name: "AES-CTR", counter: new Uint8Array(16), length: 16*8
      },
      messageKey,
      event.target.result
    ).then((ciphertext) =>
        onComplete({cipherkey: cipherkey, ciphertext: ciphertext, urlKey: btoa(messageKeyJSON)}))};
    
  reader.readAsArrayBuffer(file);  
}

export async function decryptFileWithMessageKey(blob, urlKeyJson, onComplete) {

  // Patch Blob.arrayBuffer
  (function () {
    File.prototype.arrayBuffer = File.prototype.arrayBuffer || myArrayBuffer;
    Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || myArrayBuffer;
  
    function myArrayBuffer() {
      // this: File or Blob
      return new Promise((resolve) => {
        let fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.readAsArrayBuffer(this);
      })
    }
  })();

  let key = await crypto.subtle.importKey("jwk", urlKeyJson,
      {name:"AES-CTR", length: 256},
      true, // Allow exporting the key
      ["encrypt", "decrypt"]);

  let decrypted = await window.crypto.subtle.decrypt(
    {name: "AES-CTR", counter: new Uint8Array(16), length: 16*8},
    key,
    await blob.arrayBuffer()
  );

  const data = "data:image/png;base64," + base64ArrayBuffer(decrypted);

  onComplete(data);
}

export async function decrypt(ciphertext, privateKey) {
    let decrypted = await window.crypto.subtle.decrypt(
        {
          name: "RSA-OAEP"
        },
        privateKey,
        ciphertext
      );
    let dec = new TextDecoder();
    return dec.decode(decrypted);
}

export async function decryptWithMessageKey(ciphertext, cipherkey, privateKey) {
  let jsonKey = await decrypt(cipherkey, privateKey);
  let key = await crypto.subtle.importKey(
    "jwk",
    JSON.parse(jsonKey),
    {name:"AES-CTR", length: 256},
    true,
    ["encrypt", "decrypt"]
  );
  let decrypted = await window.crypto.subtle.decrypt(
    {name: "AES-CTR", counter: new Uint8Array(16), length: 16*8},
    key,
    ciphertext
  );
  let dec = new TextDecoder();
  return dec.decode(decrypted);
}

export async function generateMessageKey() {
  return await crypto.subtle.generateKey(
    {name:"AES-CTR", length: 256},
    true, // Allow exporting the key
    ["encrypt", "decrypt"]);
}

export async function importKeysFromLocalStorage() {
  var privateKeyBase64 = localStorage.getItem("privateKey");
  var publicKeyBase64 = localStorage.getItem("publicKey");
  return importKeys(privateKeyBase64, publicKeyBase64);
}

async function importKeys(privateKeyBase64, publicKeyBase64) {
  var importedPrivateKey = await window.crypto.subtle.importKey(
    "jwk", JSON.parse(window.atob(privateKeyBase64)),
    { name: "RSA-OAEP",
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-256",
    },
    true,
    ["decrypt"]);
  var importedPublicKey = await window.crypto.subtle.importKey(
    "jwk", JSON.parse(window.atob(publicKeyBase64)),
    { name: "RSA-OAEP",
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-256",
    },
    true,
    ["encrypt"]);

  return {publicKey: importedPublicKey, privateKey: importedPrivateKey};
}

export async function generateKeyPair() {
  localStorage.setItem("encryptionKeys", "generating");
  var keyPair = await window.crypto.subtle.generateKey(
    { name: "RSA-OAEP",
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-256",
    },
    true,
    ["encrypt", "decrypt"]
  );
  
  var exportedPub = await window.crypto.subtle.exportKey(
      "jwk",
      keyPair.publicKey
  );
  localStorage.setItem("publicKey", window.btoa(JSON.stringify(exportedPub)));

  var exportedPriv = await window.crypto.subtle.exportKey(
      "jwk",
      keyPair.privateKey
    );
  localStorage.setItem("privateKey", window.btoa(JSON.stringify(exportedPriv)));
  localStorage.setItem("encryptionKeys", "generated");

  return {privateKey: exportedPriv, publicKey: exportedPub}
}

export const writeKeyCheck = (uid, pubkey, overwrite) => {
  encrypt("TECHO", pubkey).then(encrypted => {
    writeKeyChecker(uid, encrypted, overwrite);
  });
}

export async function injectKeys(uid, keyString) {
  if (!keyString.startsWith("TECHOKEY/")) {
    return {error: "Key string not valid."}
  }
  let splits = keyString.split("TECHOKEY/")[1].split("////");

  // This import validates both keys
  let keys = await importKeys(splits[0], splits[1]);
  if (keys.publicKey && keys.privateKey) {
    localStorage.setItem("privateKey", splits[0]);
    localStorage.setItem("publicKey", splits[1]);
    writeKeyCheck(uid, keys.publicKey, true);
    return {success: "Keys injected"}
  }


  return {error: "Keys did not parse."};
}