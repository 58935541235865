import React from "react";
import "./DashboardSection.scss";

import { parseGoals } from "../util/goals.js";

function GoalsTopSection(props) {

  const intervalFor = (period) => {
      if (period === "year") return <><b>monthly</b> on <b>1st</b> of the month</>;
      if (period === "half") return <><b>monthly</b> on 1st of the month</>;
      if (period === "quarter") return <><b>weekly</b> on <b>Sunday</b></>;
      if (period === "month") return <><b>weekly</b> on <b>Sunday</b></>;
  }

  const goalsUi = (md) => {
      const goals = parseGoals(md);
      return goals.map((k) => <p key={k.name} ><i>{k.name}</i> - prompt {intervalFor(k.type)}</p>);
  }

  return (
    <div className="columns is-desktop mt-5">
        <div className="column is-3-desktop">
        <div className="content">
            <span className="DashboardSection__pagetitle">🎯 Goals</span>
        </div>
        </div>
        <div className="column is-4-desktop">
            Tekkoh will help you track these goals:
            {goalsUi(props.markdown)}
        </div>
    </div>
  );
}

export default GoalsTopSection;
