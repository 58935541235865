
import React, {useState, useEffect, useContext, createContext} from "react";

const globalContext = createContext();

export function ProvideGlobals({ children }) {
  const globals = useProvideGlobals();
  return <globalContext.Provider value={globals}>{children}</globalContext.Provider>;
}
  
export const useGlobals = () => {
    return useContext(globalContext);
};


const useProvideGlobals = () => {
  const [onFire, setOnFire] = useState(null);

  useEffect( () => {
      console.log("fire:", onFire);
  },[onFire]);

  return {
    onFire,
    setOnFire
  };
}