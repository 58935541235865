import React from "react";
import "./FirePlace.scss";

function FirePlace(props) {

  return (
    <div className="FirePlace">
      <div className="fire">
      <span className="fmoji">🔥</span>
      <div className="flames" anim={props.onfire}>
          <div className="flame"></div>
          <div className="flame"></div>
          <div className="flame"></div>
          <div className="flame"></div>
      </div>
      </div>
    </div>
  );
}

export default FirePlace;
