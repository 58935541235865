import React from "react";
import DashboardSection from "./../components/DashboardSection";
import { requireAuth } from "./../util/auth.js";
import { useRouter } from "./../util/router.js";

function GoalsPage(props) {
  const router = useRouter();

  return (
    <DashboardSection
      color="white"
      size="medium"
      title="Goals"
      day={router.query.day}
      type="goals"
      ></DashboardSection>
  );
}

export default requireAuth(GoalsPage);
