import React from "react";
import "./../styles/global.scss";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import JournalPage from "./journal";
import GoalsPage from "./goals";
import DashboardPage from "./dashboard";
import SettingsPage from "./settings";
import PurchasePage from "./purchase";
import AuthPage from "./auth";
import { Switch, Route, Router } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import { ProvideAuth } from "./../util/auth.js";
import { ProvideGlobals } from "../util/global";
import Feedback from "../components/Feedback";

function App(props) {

  return (
    <ProvideAuth>
    <ProvideGlobals>
      <Router>
        <>
          <Navbar
            color="white"
            spaced={true}
          ></Navbar>

          <Switch>
            <Route exact path="/" component={IndexPage} />

            <Route exact path="/about" component={AboutPage} />

            <Route exact path="/faq" component={FaqPage} />

            <Route exact path="/pricing" component={PricingPage} />

            <Route exact path="/contact" component={ContactPage} />

            <Route exact path="/today" component={DashboardPage} />

            <Route exact path="/journal/:year/:month/:day" component={JournalPage} />

            <Route exact path="/goals" component={GoalsPage} />

            <Route exact path="/settings/:section" component={SettingsPage} />

            <Route exact path="/purchase/:plan" component={PurchasePage} />

            <Route exact path="/auth/:type" component={AuthPage} />

            <Route
              exact
              path="/email-verify"
              component={FirebaseActionPage}
            />

            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            color="light"
            size="normal"
            backgroundImage=""
            backgroundImageOpacity={1}
            copyright="© 2021"
          ></Footer>
          <Feedback>Feedback</Feedback>
        </>
      </Router>
    </ProvideGlobals>
    </ProvideAuth>
  );
}

export default App;
