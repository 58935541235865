import React, { useState, useEffect } from "react";
import NavbarContainer from "./NavbarContainer";
import { Link } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import "./Navbar.scss";
import { useUser } from "../util/db";
import FirePlace from "./FirePlace";
import { useGlobals } from "../util/global";

function Navbar(props) {
  const auth = useAuth();
  const globals = useGlobals();
  const [menuOpen, setMenuOpen] = useState(false);
  const uid = auth.user && auth.user.uid;
  const {data: user, status} = useUser(uid);

  const getStreak = () => {
    return user && user.streak && parseInt(user.streak); //streak != null && (streak + (addToday ? 1 : 0))
  };

  useEffect(() => {
    console.log("Navbar onFire ", globals.onFire);
  }, [globals.onFire]);

  return (
    <NavbarContainer spaced={props.spaced} color={props.color}>
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item navbar-logo">
            <Link to="/">
              <i className="fas fa-book"></i> Tekkoh
            </Link>
          </div>
          {(getStreak() != null && getStreak() > 0) && 
          <div className="navbar-item streak has-tooltip-bottom" data-tooltip="Complete an entry before midnight each day to keep your streak alive.">
              <FirePlace onfire={globals.onFire}/>&nbsp;&nbsp;&nbsp;&nbsp;<b>{getStreak()}</b>&nbsp;day streak.
          </div>
          }
          
          <div
            className={"navbar-burger burger" + (menuOpen ? " is-active" : "")}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
          <div className="navbar-end">
            {auth.user && (
              <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link" to="/">
                  {auth.user.email} { auth.user.planIsActive &&
                    <>&nbsp;<i className="fas fa-hands-helping pro-badge">pro</i></>}
                </Link>
                <div className="navbar-dropdown is-boxed">
                  <Link className="navbar-item" to="/today">
                    Today
                  </Link>
                  <Link className="navbar-item" to="/goals">
                    Goals
                  </Link>
                  <Link className="navbar-item" to="/settings/general">
                    Settings
                  </Link>
                  <hr className="dropdown-divider"></hr>
                  <Link
                    className="navbar-item"
                    to="/auth/signout"
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign out
                  </Link>
                </div>
              </div>
            )}

            {!auth.user && (
              <Link className="navbar-item" to="/auth/signin">
                Sign in
              </Link>
            )}
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
}

export default Navbar;
