import React, { useEffect, useState } from "react";
import HeroSection from "./../components/HeroSection";
import { useRouter } from "./../util/router.js";
import { useAuth } from "../util/auth";
import FeaturesSection from "../components/FeaturesSection";

function IndexPage(props) {
  const router = useRouter();
  const auth = useAuth();
  const [authRead, setAuthRead] = useState(false);

  useEffect(() => {
    if (auth.user) {
      router.push("/today");
    } else if (auth.user === false) {
      setAuthRead(true);
    }
  });

  if (!authRead) {
    return <></>
  }

  return (
    <>
      <HeroSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title="Tekkoh - the secure daily journal"
        subtitle="Keep a daily journal, available across your devices. Encrypted locally. It's like end-to-end encryption for your most precious thoughts."
        buttonText="Get Started"
        image="/bonsai.svg"
        buttonOnClick={() => {
          router.push("/today");
        }}
      ></HeroSection>
      <FeaturesSection
      title="How it works"
      >
      </FeaturesSection>
    </>
  );
}

export default IndexPage;
