import React from "react";
import Section from "./Section";
import { Link } from "./../util/router.js";
import "./Footer.scss";

function Footer(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
      className="footer"
    >
      <div className="FooterComponent__container container">
        <div className="brand left">
          <Link to="/">
            <i className="fas fa-book"></i> Tekkoh
          </Link>
        </div>
        
        <div className="copyright right">{props.copyright}</div>
        {props.bonsai &&
          <div className="right">Bonsai illustration by&nbsp;<a href="https://www.flaticon.com/free-icon/bonsai_2362691" title="ultimatearm">ultimatearm</a>&nbsp;from&nbsp;<a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
        }
      </div>
      {!props.bonsai && <>
      <h4>Shortcut keys</h4>
      <p> <b>cmd + esc</b>: toggle editor focus </p>
      <p> <b>alt + left</b>: previous day </p>
      <p> <b>alt + right</b>: next day </p>
      </>}
    </Section>
  );
}

export default Footer;
