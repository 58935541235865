import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Pricing from "./Pricing";

function PricingSection(props) {
  return (
    <Section
      id="pricing"
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className="has-text-centered"
        ></SectionHeader>
        <Pricing
          buttonText="Choose"
          currentButtonText="Current"
          items={[
            {
              id: "starter",
              name: "Starter",
              price: "Free",
              perks: [
                "Unlimited encrypted entries",
                "Streak reminders",
              ],
              current: true,
            },
            {
              id: "pro",
              name: "Pro",
              price: "24",
              perks: [
                "Unlimited encrypted entries",
                "Streak reminders",
                "Unlimited encrypted image uploads",
                "Streak badges",
                "Support the development of Tekkoh",
              ],
              current: false,
            },
          ]}
        ></Pricing>
      </div>
    </Section>
  );
}

export default PricingSection;
