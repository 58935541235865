import React, { useState } from "react";
import "./Feedback.scss";
import SectionButton from "./SectionButton";
import { useAuth } from "../util/auth";


function Feedback(props) {

    const [formVisible, setFormVisible] = useState(false);
    const auth = useAuth();

  return (
    <>
    {formVisible && 
    <div className="Feedback__form">
        <article class="Feedback__message message">
        <div class="message-header">
        <p>👋 Feedback</p>
        <button class="delete" aria-label="delete" onClick={() => {setFormVisible(false);}}></button>
    </div>
            <form name="feedback" method="POST" netlify data-netlify="true">
                <input type="hidden" name="form-name" value="feedback" />
                <input type="hidden" name="user" value={(auth && auth.user && auth.user.email) || "logged out"} />
            <p>
                <textarea className="textarea Feedback__textarea" name="message"></textarea>
            </p>
            <p>
            <div class="control">
                <button class="button is-link is-primary Feedback__button" type="submit"><i className="fas fa-paper-plane"></i>&nbsp;&nbsp;Send</button>
            </div>
            </p>
            </form>
        </article>
      </div>
    }

      <div className="Feedback__button">
          <SectionButton 
          size="medium"
          onClick={() => {setFormVisible(true);}}
          >💌 Feedback</SectionButton>
      </div>
    </>
  );
}

export default Feedback;
